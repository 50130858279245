/* Fold Text */
.fold-title-section {
    display: block;
    height: auto;
    margin-top: 0px;
    padding: 90px 0px 34px;
    flex-direction: column;
    align-items: center;
    grid-column-gap: 80px;
    grid-row-gap: 80px;
}

.lang-ro .fold-title-section {
    padding: 90px 0px 60px;
}

.fold-title-text {
    display: flex;
    padding: 0 20px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    grid-column-gap: 16px;
    grid-row-gap: 16px;
    text-align: center;
}

.fold-caption {
    color: #fff;
    line-height: 20px;
    font-weight: 700;
    text-transform: uppercase;
}

.fold-title {
    display: block;
    margin-top: 0px;
    margin-bottom: 0px;
    padding-bottom: 4px;
    background-color: transparent;
    opacity: 1;
    font-family: Mulish, sans-serif;
    color: #101120;
    font-size: 73px;
    line-height: 96px;
    font-weight: 700;
}

@media screen and (max-width: 1920px) {
    .fold-title {
        font-size: 3.8vw;
        line-height: 5vw;
    }
}

.fold-paragraph {
    width: 100%;
    max-width: 700px;
    margin-bottom: 0px;
    color: #101120;
    font-size: 16px;
    line-height: 28px;
    font-weight: 400;
    letter-spacing: 0.2px;
}

.fold-light .fold-title {
    color: #101120;
}

.fold-light .fold-paragraph {
    color: #2b2d42;
}

@media screen and (max-width: 991px) {
    .fold-title {
        font-size: 34px !important;
        line-height: 44px !important;
    }
}