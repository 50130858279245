.faq-fold {
    /* min-height: 70vh; */
    /* padding-bottom: 80px; */
    /* padding-top: 100px; */
    /* margin-top: 60px; */
    text-align: center;
}

.faq-fold .faq-container {
    background-image: url('https://dist.thecoopnetwork.io/plugins/faq-accordion/media/howitworks_bg.jpeg'), url('https://dist.thecoopnetwork.io/plugins/faq-accordion/media/howitworks_bg.webp');
    background-size: cover;
    border-radius: 32px;
    padding-left: 30px;
    padding-right: 30px;
    padding-bottom: 76px;
}

.faq-fold .fold-title {
    font-size: 46px;
    font-weight: 600;
    line-height: 70px;
}

.faq-container {
    height: 100% !important;
}

.faqs {
    height: 450px;
}

.faqs .faq {
    cursor: pointer;
}

.faqs .faq .faq-question {
    position: relative;
    padding-top: 11px;
    padding-right: 50px;
    transition: all 0s ease;
    border-top: 1px solid rgb(255 255 255 / 21%);
    font-family: Mulish, sans-serif;
    color: #101120;
    font-size: 16px;
    font-weight: 700;
    line-height: 31px;
    text-align: left;
    padding-left: 10px;
}

.faqs .faq .faq-question::after {
    content: "+";
    position: absolute;
    top: 60%;
    right: 0px;
    transform: translateY(-50%);
    width: 30px;
    height: 30px;
    transition: all 0.2s ease;
}

.faqs .faq .faq-answer {
    padding-bottom: 10px;
    opacity: 0;
    max-height: 0;
    width: 95%;
    overflow: hidden;
    transition: all 0s ease;
    font-family: Mulish, sans-serif;
    color: #2b2d42;
    font-size: 15px;
    line-height: 26px;
    font-weight: 400;
    text-align: left;
    white-space: pre-line;
    padding-left: 10px;
}

.faqs .faq.open .faq-answer {
    max-height: 1000px;
    opacity: 1;
    transition: all 0.6s ease;
}

.faqs .faq.open .faq-question {
    margin-bottom: 15px;
    transition: all 0.4s ease;
}

.faqs .faq.open .faq-question::after {
    content: "-";
}

.faq-link {
    font-family: Mulish, sans-serif;
    color: #2b2d42;
    font-size: 16px;
    line-height: 26px;
    font-weight: 600;
}

.more-faqs-button {
    font-family: Mulish, sans-serif !important;
    line-height: 26px !important;
    font-weight: 600 !important;
    text-decoration: none !important;
    font-size: 14px !important;
    letter-spacing: 0.5px !important;
    background: #5546fb !important;
    color: #fff !important;
    padding: 11px 30px !important;
    border-radius: 6px !important;
    float: right;
}

.faq-id {
    display: none;
}

@media screen and (min-width: 768px) and (max-width: 991px) {
    .faqs {
        min-height: 60vh;
    }
}

@media screen and (max-width: 767px) {
    .more-faqs-button {
        float: initial;
        margin-bottom: -30px !important;
    }

    .faq-fold .faq-container {
        padding-left: 20px;
        padding-right: 20px;
    }
}

@media screen and (min-width: 550px) and (max-width: 767px) {
    .faqs {
        min-height: 65vh;
    }
}

@media screen and (max-width: 991px) {
    .faqs {
        height: auto;
        min-height: 110vh;
    }
}