.faq-page .faq-fold {
    background: transparent;
    /* padding-top: 180px; */
    margin-top: 0;
}

.faq-page .faq-fold .faq-container {
    padding-bottom: 0;
    background: none;
    border-radius: 0;
}

.faq-page .faqs {
    height: auto;
}

.faq-page .more-faqs-button {
    display: none;
}

.faq-page .faq-fold-title {
    font-size: 38px;
}

.faq-page .faq-fold-title,
.faq-page .faq-question {
    color: #101120 !important;
}

.faq-page .faq .faq-answer,
.faq-page .faq-link {
    color: #2b2d42;
}

.faq-page .faq-id {
    display: inline;
}

.faq-page .faq-link {
    font-size: 14px;
}

.faq-contact {
    padding-bottom: 120px;
    font-family: Mulish, sans-serif;
    font-size: 16px;
    line-height: 26px;
    font-weight: 400;
    text-align: center;
}

.faq-page .faqs .faq.open {
    background: rgb(134 138 174 / 5%);
}

.faq-page .faqs .faq .faq-question {
    color: #6b6b6d !important;
    border-top: 1px solid rgb(0 0 0 / 5%);
    font-family: 'Mulish', sans-serif;
    font-size: 18px;
    transition: all 0s;
    padding-left: 20px;
}

.faq-page .faqs .faq.open .faq-question {
    color: #101120 !important;
    font-weight: 600;
    padding-top: 22px;
}

.faq-page .faqs .faq .faq-answer {
    font-weight: 400;
    padding-left: 20px;
    box-sizing: border-box;
}

.faq-page .faqs .faq.open .faq-answer {
    padding-bottom: 28px;
}

@media screen and (max-width: 1500px) {
    .faq-fold {
        margin-top: 130px;
    }
}

@media screen and (max-width: 991px) {
    .faq-fold {
        margin-top: 0px;
    }

    .faq-page .faq-fold .fold-title-section {
        padding-top: 0;
    }

    /* .faq-page .faq-fold {
        padding-top: 24vh;
    } */

    .faq-fold .fold-title {
        font-size: 32px;
        line-height: 46px;
    }

    .fold-title-section {
        padding: 70px 0px 40px;
    }

    .faqs {
        height: auto;
        min-height: 110vh;
    }
}